<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                非凸算法上线华鑫证券，携手共创智能交易新生态
              </p>
              <div class="fst-italic mb-2">2024年1月23日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >华鑫证券</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/72/01-华鑫上线.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    近日，非凸智能算法正式上线华鑫证券，携手共创智能交易新生态。合作过程中，非凸科技直面需求、及时响应的态度，以及稳扎稳打的技术实力和工程能力，获得了极大认可。
                  </p>
                  <p>
                    一直以来，华鑫证券秉承着“以人为本、客户至上、专业专注、创造价值”的核心价值观，坚持“金融科技引领业务发展”的差异化发展道路，已逐步构建可持续发展的金融科技创新驱动力，致力于为客户提供交易、投融资、财富管理等高品质的服务和产品。此次，非凸智能算法接入华鑫奇点极速交易柜台，将会促进智能化和场景化业务更好的落地，为投资者带来更极致的交易体验。
                  </p>
                  <p>
                    非凸科技针对华鑫证券的交易特点，升级了算法交易策略，丰富了算法使用场景，降低了交易成本，大幅提高了执行效率，也进一步提升了交易智能化能力。通过更智能的交易算法选择，可以为客户提供更精准、更便捷、更切实的交易价值与体验，并更加稳定。
                  </p>
                  <p>
                    在全面升级智能化交易的同时，也在进一步完善风险管控措施。非凸智能算法支持风险参数的灵活配置，异常情况下的预警和停止，充分适应各种监管要求，及时规避市场异动风险，可以助力券商机构全方位降低交易风险。
                  </p>
                  <p>
                    要实现业务与技术的双向奔赴，更多的是要进行多环节的沟通和配合。研发团队专注策略思考，持续精进，满足需求；产品团队密切配合，提供产品研发支持；技术团队积极解决问题，持续突破交易技术创新；运维团队提供7*24小时线上/线下沟通支持，做好安全保障。
                  </p>
                  <p>
                    未来，非凸科技将持续加强智能交易技术的攻关应用，助力券商机构实现交易智能化，推动金融行业高质量发展。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News72",
};
</script>

<style></style>
